<template>
<!-- 评价管理 -->
  <div class="box100 whiteBg">
    <div class="flexAC">
      <el-date-picker v-model="faultstime" type="date" value-format="yyyy-MM-dd" placeholder="选择时间" class="dateSearch"></el-date-picker>
      <el-input v-model="indentid"  placeholder="请输入订单号/门店名称" class="inputSearch"></el-input>
      <el-button class="btnSearch"  @click="handleSearch">搜索</el-button>
      <el-button class="btnReset"  @click="handleReset">重置</el-button>
    </div>
    <div class="flexAC" style="margin: 16px 0px;">
      <div class="evaluateBox"
           :class="aeinfolevel === tab.val ? 'evaActive': ''"
           v-for="tab in evaluateType" :key="tab.index"
            @click="changeEva(tab)">
        {{ tab.name }}
      </div>
    </div>
    <div class="table_list">
      <el-table
        :header-cell-style="{background:'#EBF2FE'}"
        :data="afinfoList">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="评价内容"
          prop="aeinfomark"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="评价星级"
          prop="aeinfolevel">
        </el-table-column>
        <el-table-column
          label="评价用户"
          prop="username">
        </el-table-column>
        <el-table-column
          label="评价时间"
          prop="creatime"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="回复内容"
          prop="aeinforeply"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="关联服务"
          prop="servename"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="关联订单编号"
          prop="indentid"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="关联门店"
          prop="shopname">
        </el-table-column>
        <el-table-column
          label="关联美容师"
          prop="sbecode">
        </el-table-column>
        <el-table-column
          width="100"
          label="操作">
          <template slot-scope="scope">
            <template v-if="roleBtn[1].power">
              <el-button type="text" size="small" @click="showReply(scope.row)">回复</el-button>
            </template>
            <template v-if="roleBtn[0].power">
              <el-button type="text" size="small" style="color:#F44C8E;" @click="showDel(scope.row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="pageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>
    <!-- 回复弹窗 -->
    <el-dialog title="回复" :visible.sync="showInfo" width="1200px" center class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <p>{{reply.username}}</p>
      <p class="recont">{{reply.aeinfomark}}</p>
      <div v-if="reply.aeinforeply != ''">
        <p class="redit">商家客服</p>
        <p class="retit">{{reply.aeinforeply}}</p>
      </div>
      <div class="form-group flexCB">
        <el-input type="textarea" v-model="aeinforeply" class="resizeNone" rows="3" placeholder="请输入要回复的内容(最多200字)" maxlength="200"></el-input >
        <div class="btn-success" @click="changSend">发送</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { afterinfoGet, afterinfoUpdate, afterinfoDelete, afterlevelDelete } from '@/api/after'
export default {
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      faultstime: '',
      indentid: '',
      evaluateType: [
        { name: '全部', val: 0 },
        { name: '好评', val: 3 },
        { name: '中评', val: 2 },
        { name: '差评', val: 1 }
      ],
      aeinfolevel: 0,
      afinfoList: [],
      showInfo: false,
      reply: {},
      aeinforeply: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        aeinfolevel: this.aeinfolevel,
        faultstime: this.faultstime,
        indentid: this.indentid,
        page_index: this.page,
        page_size: this.size
      }
      afterinfoGet(params).then(res => {
        if (res.status === 1) {
          this.afinfoList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.afinfoList = []
          this.total = 0
        }
      })
    },
    // 发布
    changSend () {
      const params = {
        aeinfoid: this.reply.aeinfoid,
        aeinforeply: this.aeinforeply,
      }
      afterinfoUpdate(params).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.FromCancel()
          this.init()
        } else {
        }
      })
    },
    FromCancel () {
      this.aeinforeply = ''
      this.showInfo = false
    },
    // 切换tab
    changeEva (item) {
      this.aeinfolevel = item.val
      this.init()
    },
    // 搜索
    handleSearch () {
      this.init()
    },
    // 重置
    handleReset () {
      this.faultstime = ''
      this.indentid = ''
      this.init()
    },
    // 翻页
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 点击回复
    showReply (row) {
      this.showInfo = true
      this.aeinforeply = ''
      this.reply = {...row}
    },
    FromCancel () {
      this.showInfo = false
    },
    // 删除
    showDel (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        afterinfoDelete({ aeinfoid: row.aeinfoid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.evaluateBox{
  width: 80px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #A5B7CC;
  color: #A5B7CC;
  font-size: 14px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  margin-right: 16px;
  cursor: pointer;
}
.evaActive{
  background: #3C84EF;
  border: 1px solid #3C84EF;
  color: #fff;
}
.recont {
  /* height: 146px; */
  background: #F7F9FB;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px;
}
.redit {
  font-size: 14px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #333333;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.retit {
  height: 78px;
  background: #6BEC6B;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  margin: 10px 0;
}
.btn-success {
  width: 120px;
  height: 75px;
  background: #3C84EF;
  border-radius: 4px;
  text-align: center;
  line-height: 75px;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
}
.form-group >>> .el-textarea__inner {
  background: #F7F9FB;
  border-radius: 4px;
  width: calc(100% - 20px);
  border: 0;
}
.form-group {
  margin-top: 100px;
}
</style>
<style>
.el-input__icon {
  line-height: 33px;
}
</style>
